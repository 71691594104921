import React, { useEffect, useState } from 'react';
import {
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from '../parts/sidebar';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../hooks/AuthContext';

// logo url
const stripeLogo = 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png';

const paypalLogo = 'https://cdn.pixabay.com/photo/2015/05/26/09/37/paypal-784404_1280.png';

function Subscriptions() {
  const [submit, setSubmit] = useState(false);
  const [creditSubmit, setCreditSubmit] = useState(false);
  // for control only one payment spinner
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [creditsModal, setCreditsModal] = useState(false);

  // for select payment method modal when buy credit
  const [purchasePaymentMethodModal, setPurchasePaymentMethodModal] = useState(false);

  // for select payment method modal when subscription
  const [subscriptionPaymentMethodModal, setSubscriptionPaymentMethodModal] = useState(false);
  const [value, setValue] = useState(1);
  const [price, setPrice] = useState({});
  const [isVatFree, setIsVatFree] = useState(false);

  const { dbUserId } = useAuth();
  const getPricing = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/getPricing`,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const responseData = response.data;
      setPrice(responseData.data[0]);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  async function getRequests() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/profile?id=${dbUserId}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const data = await response.json();
      setIsVatFree(Boolean(data.data[0].isVatFree));
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    getRequests();
    getPricing();
  }, []);

  const handleDecrement = () => {
    if (value - 1 > 0) {
      setValue(value - 1);
    }
  };

  const handleIncrement = () => {
    setValue(value + 1);
  };

  const showErrorMessage = () => {
    toast.error('You Already have a subscription', {
      position: 'top-center',
    });
  };

  async function subscribe(paymentMethod) {
    setSubmit(true);
    setSelectedPaymentMethod(paymentMethod);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/annualsubscription?userId=${dbUserId}&paymentMethod=${paymentMethod}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      setSubmit(false);
      setSelectedPaymentMethod('');

      const data = await response.json();
      if (data.message === 'already') {
        showErrorMessage();
      } else {
        window.location.href = data.sessionUrl;
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function buyCredits(paymentMethod) {
    setCreditSubmit(true);
    setSelectedPaymentMethod(paymentMethod);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/buyCredits?userId=${dbUserId}&credits=${value}&paymentMethod=${paymentMethod}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      setCreditSubmit(false);
      setSelectedPaymentMethod('');

      const data = await response.json();
      window.location.href = data.sessionUrl;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />

      <div className="main-content">
        <div style={{ marginTop: '2%', marginLeft: '3%' }}>
          <h3 style={{ color: 'white' }}>Shop</h3>

          <center>
            <div>
              <MDBRow
                className="row-cols-1 row-cols-md-3 g-4 subscription-boxes"
                style={{ margin: '30px' }}
              >
                <MDBCol>
                  <MDBCard
                    className="h-100"
                    style={{ backgroundColor: '#2d353c', borderRadius: '0' }}
                  >
                    <MDBCardBody style={{ color: '#FFFFFF' }}>
                      <MDBCardTitle>Credits</MDBCardTitle>
                      <MDBCardText>
                        {/* {pricewt: 13, pricewot: 10} */}
                        {isVatFree
                          ? `Price per credit: ${price.pricewot} € (VAT free)`
                          : `Price per credit: ${price.pricewt} € (incl. VAT)`}

                        <hr />
                        <b>Price per Solution-File: 3 Credits</b>
                        <br />
                        <i>Multiple solutions included</i>
                        <hr />
                        <b>Price per Tuning-File: 7 Credits</b>
                        <br />
                        <i>Multiple solutions included</i>
                        <br />
                        <MDBBtn
                          style={{
                            marginTop: '25px',
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                          onClick={() => {
                            setCreditsModal(true);
                          }}
                        >
                          Buy Credit(s)
                        </MDBBtn>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol>
                  <MDBCard
                    className="h-100"
                    style={{ backgroundColor: '#2d353c', borderRadius: '0' }}
                  >
                    <MDBCardBody style={{ color: '#FFFFFF' }}>
                      <MDBCardTitle>Subscription</MDBCardTitle>
                      <MDBCardText>
                        {isVatFree
                          ? ' Annual fee: 1,025 € (VAT free)'
                          : 'Annual fee: 1,200 € (incl. VAT)'}
                        <hr />
                        <b>Price per Solution-File: 10x free per day</b>
                        <br />
                        <i>Remaining quota is not transferable</i>
                        <hr />
                        <b>Price per Tuning-File: 5 Credits</b>
                        <br />
                        <i>Multiple solutions included</i>
                        <br />
                        <MDBBtn
                          style={{
                            marginTop: '25px',
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                          onClick={() => setSubscriptionPaymentMethodModal(true)}
                        >
                          <span>Buy Subscription</span>
                        </MDBBtn>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </div>
          </center>
        </div>
      </div>

      <MDBModal
        show={creditsModal}
        onHide={() => setCreditsModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered size="md">
          <MDBModalContent
            style={{ backgroundColor: '#303030', color: 'white' }}
          >
            <MDBModalBody>
              <center>
                <h3
                  style={{
                    color: 'white',
                    fontFamily: 'bahnschrift',
                    marginTop: '15px',
                  }}
                >
                  Get Credits
                </h3>
              </center>

              <center>
                <p>
                  Price:
                  {isVatFree ? price.pricewot * value : price.pricewt * value}
                  €
                </p>
                <div style={{ display: 'flex' }}>
                  <MDBBtn
                    onClick={handleDecrement}
                    outline
                    color="primary"
                    style={{
                      marginRight: '5px',
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                  >
                    -
                  </MDBBtn>
                  <MDBInput
                    id="form1"
                    type="text"
                    value={value}
                    style={{ width: '365px', color: 'white' }}
                  />
                  <MDBBtn
                    onClick={handleIncrement}
                    outline
                    color="primary"
                    style={{
                      marginLeft: '5px',
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                  >
                    +
                  </MDBBtn>
                </div>
                <MDBBtn
                  style={{
                    marginTop: '25px',
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                  onClick={() => setPurchasePaymentMethodModal(true)}
                >
                  <span>Proceed to Payment</span>
                </MDBBtn>
              </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      {/* payment method selection modal for buying credit */}
      <MDBModal
        show={purchasePaymentMethodModal}
        onHide={() => setPurchasePaymentMethodModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered size="lg">
          <MDBModalContent
            style={{
              backgroundColor: '#303030',
              color: 'white',
              padding: '60px',
            }}
          >
            <MDBModalBody>
              <h4>Select a payment Methods</h4>
              <p className="font-extrabold">
                Price:
                {' '}
                {isVatFree ? price.pricewot * value : price.pricewt * value}
                €
              </p>

              <div className="d-flex justify-content-center">
                <MDBBtn
                  outline
                  className="mx-2 flex-1 bg-white"
                  onClick={() => buyCredits('stripe')}
                  disabled={submit || creditSubmit}
                >
                  {creditSubmit && selectedPaymentMethod === 'stripe' ? (
                    <MDBSpinner />
                  ) : (
                    <>
                      <img
                        src={stripeLogo}
                        alt="Stripe"
                        className="mr-2"
                        width="60%"
                      />
                      Pay with Stripe
                    </>
                  )}
                </MDBBtn>
                <MDBBtn
                  outline
                  className="mx-2 flex-1 bg-white"
                  onClick={() => buyCredits('paypal')}
                  disabled={submit || creditSubmit}
                >
                  {creditSubmit && selectedPaymentMethod === 'paypal' ? (
                    <MDBSpinner />
                  ) : (
                    <>
                      <img
                        src={paypalLogo}
                        alt="PayPal"
                        className="mr-2"
                        width="60%"
                      />
                      Pay with PayPal
                    </>
                  )}
                </MDBBtn>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* payment method selection modal for subscription */}
      <MDBModal
        show={subscriptionPaymentMethodModal}
        onHide={() => setSubscriptionPaymentMethodModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog centered size="lg">
          <MDBModalContent
            style={{
              backgroundColor: '#303030',
              color: 'white',
              padding: '60px',
            }}
          >
            <MDBModalBody>
              <h4>Select a payment Methods</h4>
              <p className="font-extrabold">
                {isVatFree
                  ? ' Annual fee: 1,025 € (without VAT)'
                  : 'Annual fee: 1.200 € (incl. VAT)'}
              </p>

              <div className="d-flex justify-content-center">
                <MDBBtn
                  outline
                  className="mx-2 flex-1 bg-white"
                  onClick={() => subscribe('stripe')}
                  disabled={submit || creditSubmit}
                >
                  {submit && selectedPaymentMethod === 'stripe' ? (
                    <MDBSpinner />
                  ) : (
                    <>
                      <img
                        src={stripeLogo}
                        alt="Stripe"
                        className="mr-2"
                        width="60%"
                      />
                      Pay with Stripe
                    </>
                  )}
                </MDBBtn>
                <MDBBtn
                  outline
                  className="mx-2 flex-1 bg-white"
                  onClick={() => subscribe('paypal')}
                  disabled={submit || creditSubmit}
                >
                  {submit && selectedPaymentMethod === 'paypal' ? (
                    <MDBSpinner />
                  ) : (
                    <>
                      <img
                        src={paypalLogo}
                        alt="PayPal"
                        className="mr-2"
                        width="60%"
                      />
                      Pay with PayPal
                    </>
                  )}
                </MDBBtn>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}

export default Subscriptions;
