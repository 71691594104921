import React, { useState } from 'react';
import {
  MDBRow, MDBCol, MDBInput, MDBBtn, MDBSpinner,
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

export default function Reset() {
  const [submit, setSubmit] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();

  const { updatePassword } = useAuth();

  const showErrorMessage = () => {
    toast.error('Password & Confirm Password must be same', {
      position: 'top-center',
    });
  };
  const showSuccessMessage = () => {
    toast.success('Password has been reset', {
      position: 'top-center',
      onClose: () => {
        window.location.href = '/';
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      showErrorMessage();
    } else {
      setSubmit(true);

      const { error } = await updatePassword(password);

      if (error) {
        console.error('Error:', error.message);
        setSubmit(false);
      } else {
        showSuccessMessage();
        setSubmit(false);
        navigate('/dashboard');
      }
    }
  };

  return (
    <MDBRow style={{
      margin: 0, width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: '#1A2229',
    }}
    >
      <ToastContainer />
      <MDBCol
        md="12"
        style={{
          ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <div style={{ padding: '20px', width: '400px' }}>
          <center>
            <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} />
          </center>
          <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>Forgot Password</h3>
          <p style={{ color: '#405e77' }}>Reset Your Password</p>
          <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
            <div style={{ marginTop: '5px' }}>
              <MDBInput
                label="New Password"
                id="password"
                name="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                style={{ backgroundColor: '#6c757d' }}
              />
            </div>
            <div style={{ marginTop: '5px' }}>
              <MDBInput
                label="Confirm Password"
                id="cpassword"
                name="cpassword"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ backgroundColor: '#6c757d' }}
              />
            </div>
            <MDBBtn style={{
              marginTop: '30px', width: '100%', backgroundColor: '#c32941', color: 'white',
            }}
            >
              {submit ? (
                <MDBSpinner />
              ) : (
                <span>Reset Password</span>
              )}
            </MDBBtn>
          </form>

          <div style={{
            position: 'absolute', bottom: '20px', left: '0', right: '0', textAlign: 'center',
          }}
          >
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>
              © Eculab All Right Reserved
              {new Date().getFullYear()}
            </p>
            <div>
              <Link to="/imprint" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Imprint
              </Link>
              {' '}
              |
              {' '}
              <Link to="/conditions" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Conditions
              </Link>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
