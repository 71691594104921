/* eslint-disable react/no-unescaped-entities */
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
} from 'mdb-react-ui-kit';
import React from 'react';

function Cancel() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle tag="h5">Payment Cancelled</MDBCardTitle>
          <MDBCardText>
            Your payment has been cancelled. You haven't been charged for
            anything.
          </MDBCardText>
          <MDBBtn href="/subscriptions" color="primary">
            Return to Shop
          </MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </div>
  );
}

export default Cancel;
