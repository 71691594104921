import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './Components/login';
import Register from './Components/register';
import Dashboard from './Components/Pages/dashboard';
import Requests from './Components/Pages/requests';
import Forgot from './Components/Password/forgot';
import Reset from './Components/Password/reset';
import PreviousRequests from './Components/Pages/previousRequests';
import Subscriptions from './Components/Pages/subscription';
import Success from './Components/Pages/success';
import Credits from './Components/Pages/credits';
import Profile from './Components/Pages/profile';
import Error from './Components/404';
import { AuthProvider } from './hooks/AuthContext';
import PrivateRoute from './Components/private';
import Imprint from './Components/imprint';
import Conditions from './Components/conditions';
import Confirm from './Components/confirm';
import DataProtection from './Components/data-protection';
import Cancel from './Components/Pages/cancel';

function App() {
  return (
    <div className="app">
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/change-password" element={<Reset />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/conditions" element={<Conditions />} />
          <Route path="/data-protection" element={<DataProtection />} />
          <Route path="/confirm" element={<Confirm />} />
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/request-details/:id" element={<PreviousRequests />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/credits" element={<Credits />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
          {/* END Private Routes */}
          <Route path="*" element={<Error />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
