import React, { useState, useEffect, useRef } from 'react';
import {
  MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon,
} from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../parts/sidebar';
import { useAuth } from '../../hooks/AuthContext';

function PreviousRequests() {
  const { id } = useParams();
  const [request, setRequest] = useState([]);
  const [messages, setMessages] = useState([]);
  const messagesContainerRef = useRef(null);
  const shouldAutoScrollRef = useRef(true);
  const [file, setFile] = useState(null);
  const { dbUserId } = useAuth();

  async function getRequests() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/myrequests?id=${dbUserId}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const data = await response.json();

      setRequest(data.data.filter((item) => item.Id === id));
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleScrollDown = () => {
    const container = messagesContainerRef.current;

    if (shouldAutoScrollRef.current) {
      container.scrollTop = container.scrollHeight;
    }
  };

  async function getMessages() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Dealer/getMessages?requestId=${id}`, {
        method: 'GET',
        headers: {
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });

      const data = await response.json();

      setMessages((prevMessages) => {
        if (prevMessages.length !== data.data.length) {
          setTimeout(() => {
            handleScrollDown();
          }, 100);
        }

        return data.data;
      });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getRequests();
    getMessages();

    const intervalId = setInterval(() => {
      getMessages();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = document.getElementById('message').value;
    if (message !== '' || file) {
      const formData = new FormData();
      formData.append('message', message);
      formData.append('ticketNo', id);
      formData.append('file', file);
      formData.append('name', Cookies.get('name'));
      formData.append('recieverId', 1);
      formData.append('senderId', dbUserId);

      try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Dealer/chat`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });

        setFile(null);
        getMessages();
        document.getElementById('message').value = '';
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />

      <div className="main-content">
        <div style={{ marginTop: '2%', marginLeft: '3%' }}>
          <div style={{ display: 'flex' }}>
            {request.map((item, index) => (
              item.status === 'Open' ? (
                <div
                  key={index}
                  style={{
                    backgroundColor: '#389716', padding: '5px', borderRadius: '5px', color: 'white', height: '35px', marginLeft: '15px',
                  }}
                >
                  Opened
                </div>
              ) : (
                <div
                  key={index}
                  style={{
                    backgroundColor: 'red', padding: '5px', borderRadius: '5px', color: 'white', height: '35px', marginLeft: '15px',
                  }}
                >
                  Closed
                </div>
              )
            ))}
          </div>
          {request.map((item, index) => (
            <div style={{ margin: '20px', marginTop: '-5px' }} key={index}>
              <p style={{ color: 'white', fontWeight: 'bold' }}>Make:</p>
              <p style={{ color: 'white', marginTop: '-15px' }}>{item.make}</p>
              <p style={{ color: 'white', fontWeight: 'bold' }}>ECU / TCU</p>
              <p style={{ color: 'white', marginTop: '-10px' }}>{item.ecu}</p>
              <p style={{ color: 'white', fontWeight: 'bold' }}>Your Comment</p>
              <p style={{ color: 'white', marginTop: '-10px' }}>{item.comments}</p>
            </div>
          ))}

          <div className="conversation" style={{ marginLeft: '8px' }}>
            <h3 style={{ color: 'white', fontWeight: 'bold' }}>
              Your Conversation (Request #
              {id}
              )
            </h3>
            <center>
              <MDBRow className="justify-content-center">
                <MDBCol md={8}>
                  <div
                    className="p-2 bg-[#303030] border-2 border-gray-500 rounded-md h-64 overflow-y-scroll"
                    ref={messagesContainerRef}
                    style={{
                      height: '400px', backgroundColor: '#2d353c', borderWidth: '0', padding: '1.5rem',
                    }}
                  >
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`bg-gray-500 mb-4 rounded-md p-2 text-white ${message.senderId === dbUserId ? 'sender-user' : 'sender-admin'}`}
                      >
                        <p className="font-bold mb-1 text-[#303030]">
                          {message.senderId === dbUserId ? 'ME' : message.firstname}
                          <br />
                        </p>
                        <p className="mb-0 text-[#303030]">{message.message}</p>
                        {message.file && (
                          <p style={{
                            marginBottom: '0', fontStyle: 'italic', fontSize: '0.8em', color: '#c92841',
                          }}
                          >
                            {' '}
                            <a href={`${process.env.REACT_APP_BACKEND_URL}/files/${message.file}`} style={{ color: '#c92841', fontWeight: 'bold' }}>
                              <MDBIcon fas icon="file" />
                              {' '}
                              {message.file}
                            </a>
                          </p>
                        )}
                      </div>
                    ))}

                  </div>

                  <form onSubmit={handleSubmit}>
                    <input
                      placeholder="Your Message"
                      id="message"
                      type="text"
                      className="bg-[#303030] mt-2 text-white p-2 w-full rounded-md border-2 border-gray-500"
                    />
                    <MDBInput id="filen" type="file" style={{ backgroundColor: '#303030', marginTop: '7px', color: 'white' }} onChange={(event) => setFile(event.target.files[0])} />
                    <MDBRow style={{ marginTop: '10px' }}>
                      <MDBCol md={12}>
                        <MDBBtn style={{ backgroundColor: '#303030', width: '100%', marginTop: '5px' }} type="submit">Send Message</MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </form>
                </MDBCol>
              </MDBRow>
            </center>
            {request.map((item, index) => (
              item.status !== 'Open' ? (
                <div key={index}>
                  <h3 style={{ color: 'white', fontFamily: 'bahnschrift', marginTop: '10px' }}>Your Solution</h3>
                  <MDBRow style={{ margin: '5px' }}>
                    <MDBCol md={3}>
                      <a href={`${process.env.REACT_APP_BACKEND_URL}/files/${item.solution}`}>
                        <MDBBtn style={{
                          backgroundColor: '#303030', border: '1px solid #444748', width: '100%', marginTop: '5px',
                        }}
                        >
                          Download File
                        </MDBBtn>
                      </a>
                    </MDBCol>
                  </MDBRow>
                </div>
              ) : null
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviousRequests;
