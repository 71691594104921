import React, { useState } from 'react';
import {
  MDBRow, MDBCol, MDBInput, MDBBtn, MDBSpinner,
} from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../hooks/AuthContext';

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

export default function Login() {  
  const { signIn, userSession } = useAuth();
  const [email, setEmail] = useState('');
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();

  if (userSession) {
    navigate('/dashboard');
  }

  const showErrorMessage = () => {
    toast.error('Invalid login', {
      position: 'top-center',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);

    try {
      await signIn(email.trim(), 'password');
    } catch (error) {
      console.error('Error loggin in', error);
      showErrorMessage();
      setSubmit(false);
    }
  };

  return (
    <MDBRow style={{
      margin: 0, width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: '#1A2229',
    }}
    >
      <ToastContainer />
      <MDBCol
        md="12"
        style={{
          ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh',
        }}
      >
        <div style={{ marginLeft: '20px', padding: '20px', width: '400px' }}>
          <center>
            <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} />
          </center>
          <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>Dealer Login</h3>
          <p style={{ color: '#405e77' }}>Login Below</p>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ width: '100%' }}>
              <MDBInput
                label="Email Address"
                id="email"
                name="email"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                style={{ backgroundColor: '#6c757d' }}
              />
            </div>
            <MDBBtn style={{
              marginTop: '30px', width: '100%', backgroundColor: '#c32941', color: 'white',
            }}
            >
              {submit ? (
                <MDBSpinner />
              ) : (
                <span>Login</span>
              )}
            </MDBBtn>
          </form>

          <div className="links">
            <p style={{ color: '#adb5b3', marginTop: '15px', fontSize: '15px' }}>
              Don&apos;t have an account? Click
              {' '}
              <Link to="/register" style={{ color: 'white', fontSize: '15px' }}>here</Link>
              {' '}
              to register.
            </p>
            {/* <p style={{ color: '#adb5b3', marginTop: '-6px', fontSize: '15px' }}>
              Forgot your password? Click
              {' '}
              <Link to="/forgot-password" style={{ color: 'white', fontSize: '15px' }}>here</Link>
              {' '}
              to reset.
            </p> */}
          </div>

          <div style={{
            position: 'absolute', bottom: '20px', left: '0', right: '0', textAlign: 'center',
          }}
          >
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>
              © Eculab All Right Reserved
              {' '}
              {new Date().getFullYear()}
            </p>
            <div>
              <Link to="/imprint" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Imprint
              </Link>
              {' '}
              |
              {' '}
              <Link to="/conditions" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Conditions
              </Link>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
