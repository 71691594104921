import React, { useEffect, useState } from 'react';
import {
  MDBRow, MDBCol, MDBBtn, MDBSpinner,
} from 'mdb-react-ui-kit';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useForm from '../hooks/useForm';
import { useAuth } from '../hooks/AuthContext';

const col2Style = {
  height: '100%',
  overflow: 'auto',
  padding: '0',
  position: 'relative',
};

export default function Register() {
  const [searchParams] = useSearchParams();
  const showRegisterMessage = !!searchParams.get('show-register-message');

  const { signUp } = useAuth();

  const [submit, setSubmit] = useState(false);
  const [registerInProgress] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry] = useState('');

  const navigate = useNavigate();

  const [formData, handleChange] = useForm({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    address: '',
    postcode: '',
  });

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const countryList = response.data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
        }));
        setCountries(countryList);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  }, []);

  const showEmailMessage = (message) => {
    toast.error(message, {
      position: 'top-center',
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setSubmit(true);
    const {
      email, firstName, lastName, phone, country, city, address, postcode,
    } = formData;

    const signUpResponse = await signUp({
      email,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_FRONTEND_BASE_URL}/confirm`,
        data: {
          firstName,
          lastName,
          phone,
          country,
          city,
          address,
          postcode,
        },
      },
    });
    const { error } = signUpResponse;

    if (error) {
      setSubmit(false);
      showEmailMessage(error.message);
    }

    setSubmit(false);
    navigate('/confirm');
  }

  return (
    <MDBRow style={{
      margin: 0, width: '100%', overflow: 'hidden', backgroundColor: '#1A2229',
    }}
    >
      <ToastContainer />
      <MDBCol
        md="12"
        style={{
          ...col2Style, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <div style={{
          marginLeft: '20px', marginTop: '10%', padding: '30px', width: '500px',
        }}
        >
          <center>
            <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} />
          </center>
          <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>Sign Up</h3>
          { showRegisterMessage && (
            <div>
              <p style={{
                color: '#ffffff', background: '#0390fc', borderRadius: '18px', padding: '12px', fontWeight: '700',
              }}
              >
                You don&apos;t have an account yet. Please register.
              </p>
            </div>

          ) }
          <p style={{ color: '#405e77' }}>Register below for free</p>
          { registerInProgress ? (
            <div>
              <p style={{
                color: '#ffffff', background: '#00A86B', borderRadius: '18px', padding: '12px',
              }}
              >
                Please check your email for a link to log into your account.
                <span className="font-bold">You need to open the link from the email in the same browser.</span>
              </p>
            </div>

          ) : (
            <form onSubmit={handleSubmit} id="registerform">
              <MDBRow>
                <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                  Name
                  <span style={{ color: 'red' }}>*</span>
                </p>
                <MDBCol md="6" sm={12}>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px' }}
                    placeholder="First Name"
                    name="firstName"
                    onChange={handleChange}
                    required
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                  </style>
                </MDBCol>

                <MDBCol md="6" sm={12}>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px' }}
                    placeholder="Last Name"
                    name="lastName"
                    onChange={handleChange}
                    required
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                  </style>
                </MDBCol>
              </MDBRow>

              <MDBRow style={{ marginTop: '5px' }}>
                <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                  Email
                  <span style={{ color: 'red' }}>*</span>
                </p>
                <MDBCol md="12">
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px' }}
                    placeholder="Email address"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                  </style>
                </MDBCol>
              </MDBRow>

              <MDBRow style={{ marginTop: '5px' }}>
                <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                  Phone
                  <span style={{ color: 'red' }}>*</span>
                </p>
                <MDBCol md="12">
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px' }}
                    placeholder="Phone"
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                  </style>
                </MDBCol>
              </MDBRow>

              <MDBRow style={{ marginTop: '5px' }}>
                <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                  Location
                  <span style={{ color: 'red' }}>*</span>
                </p>
                <MDBCol md="6" sm={12}>
                  <select
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px', color: '#95a9be' }}
                    value={selectedCountry[0]}
                    name="country"
                    onChange={handleChange}
                    required
                  >
                    {countries.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </MDBCol>

                <MDBCol md="6" sm={12}>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px' }}
                    placeholder="City"
                    name="city"
                    onChange={handleChange}
                    required
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                  </style>
                </MDBCol>
              </MDBRow>

              <MDBRow style={{ marginTop: '5px' }}>
                <p style={{ fontSize: '15px', marginBottom: '5px' }}>
                  Address
                  <span style={{ color: 'red' }}>*</span>
                </p>
                <MDBCol md="6" sm={12}>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px' }}
                    placeholder="Address"
                    name="address"
                    onChange={handleChange}
                    required
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                  </style>
                </MDBCol>

                <MDBCol md="6" sm={12}>
                  <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#6c757d', marginTop: '5px' }}
                    placeholder="Post Code"
                    name="postcode"
                    onChange={handleChange}
                    required
                  />
                  <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                  </style>
                </MDBCol>
              </MDBRow>

              <MDBBtn style={{
                marginTop: '30px', width: '100%', backgroundColor: '#c32941', color: 'white',
              }}
              >
                {submit ? (
                  <MDBSpinner />
                ) : (
                  <span>Sign Up</span>
                )}
              </MDBBtn>
            </form>
          )}

          <div className="links">
            <p style={{ color: '#adb5b3', marginTop: '15px', fontSize: '15px' }}>
              Already have an account? Click
              {' '}
              <Link to="/" style={{ color: 'white', fontSize: '15px' }}>here</Link>
              {' '}
              to Login.
            </p>
          </div>

          <div style={{
            left: '0', right: '0', textAlign: 'center', marginTop: '25px',
          }}
          >
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>
              © ECULABs All Right Reserved
              {new Date().getFullYear()}
            </p>
            <div>
              <Link to="/imprint" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Imprint
              </Link>
              {' '}
              |
              {' '}
              <Link to="/conditions" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Conditions
              </Link>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
