import React, { useEffect, useState } from 'react';
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import Sidebar from '../parts/sidebar';
import { useAuth } from '../../hooks/AuthContext';

// Beispiel für die Definition der getStatusClassName Funktion
function getStatusClassName(status) {
  if (status === 'Open') {
    return 'open-status';
  } if (status === 'Pending') {
    return 'pending-status';
  }
  return 'closed-status';
}

function Requests() {
  const [requests, setRequests] = useState([]);

  const { dbUserId } = useAuth();

  async function getRequests() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/myrequests?id=${dbUserId}`,
      );

      const data = await response.json();
      const reversedArray = [...data.data].reverse();
      setRequests(reversedArray);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />

      <div className="main-content">
        <div style={{ marginTop: '2%', marginLeft: '3%', marginRight: '3%' }}>
          <h3 style={{ color: 'white', fontFamily: 'bahnschrift' }}>My requests</h3>
          <div className="card text-center" style={{ margin: '30px', backgroundColor: 'rgb(45, 53, 60)' }}>
            <div className="card-body">
              <div className="table-responsive">
                <MDBTable>
                  <thead>
                    <tr>
                      <th>Request</th>
                      <th>Make</th>
                      <th>Controller</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <MDBTableBody>
                    {requests.map((item) => (
                      <tr key={item.Id}>
                        <td data-label="Request">{item.Id}</td>
                        <td data-label="Make">{item.make}</td>
                        <td data-label="Controller">{item.ecu}</td>
                        <td data-label="Date">{new Date(item.dated).toLocaleString()}</td>
                        <td data-label="Status">
                          <span className={`status-badge ${getStatusClassName(item.status)}`}>
                            {item.status}
                          </span>
                        </td>
                        <td data-label="Details">
                          <Link to={`/request-details/${item.Id}`}>
                            <i className="fa fa-eye" aria-hidden="true" aria-label="View Details" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Requests;
