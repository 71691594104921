import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import './sidebar.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useAuth } from '../../hooks/AuthContext';

export default function App() {
  const [showShow, setShowShow] = useState(false);
  const [supportActive, setSupportActive] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [credits, setCredits] = useState([]);

  const { signOut, dbUserId } = useAuth();
  const getSupport = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/getStatuses`,
        {},
      );
      const responseData = response.data.data;
      const status = responseData.some((item) => item.Active === 1);

      setSupportActive(status);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  async function subscribe() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/subCredits?userId=${dbUserId}`,
      );

      const subCreditData = await response.json();
      setSubscriptionData(subCreditData.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function credit() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/getCredits?userId=${dbUserId}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const creditData = await response.json();
      setCredits(creditData.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getSupport();
    subscribe();
    credit();
  }, []);

  const toggleShow = () => setShowShow(!showShow);

  return (
    <>
      <MDBCollapse
        show={showShow}
        tag="nav"
        className="d-lg-flex sidebar"
        style={{ backgroundColor: '#2d353c' }}
      >
        <div className="position-sticky">
          <MDBListGroup className="mx-3 mt-4">
            <p
              style={{
                marginLeft: '23px',
                marginBottom: '-2px',
                color: '#ffffff',
                fontSize: '15px',
              }}
            >
              General
            </p>
            <MDBListGroupItem
              tag="a"
              href="/dashboard"
              className="border-0"
              style={{
                backgroundColor: '#2d353c',
                color: 'white',
                fontSize: '15px',
              }}
            >
              <MDBIcon
                fas
                icon="cloud-upload me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
              Get your File
            </MDBListGroupItem>

            <p
              style={{
                marginLeft: '23px',
                marginBottom: '-2px',
                color: '#ffffff',
                fontSize: '15px',
                marginTop: '25px',
              }}
            >
              Requests
            </p>
            <MDBListGroupItem
              tag="a"
              href="/requests"
              className="border-0"
              aria-current="true"
              style={{
                backgroundColor: '#2d353c',
                color: '#ffffff',
                fontSize: '15px',
              }}
            >
              <MDBIcon
                fas
                icon="list-alt me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
              My requests
            </MDBListGroupItem>

            <p
              style={{
                marginLeft: '23px',
                marginBottom: '-2px',
                color: '#ffffff',
                fontSize: '15px',
                marginTop: '25px',
              }}
            >
              Subscriptions
            </p>
            <MDBListGroupItem
              tag="a"
              href="/subscriptions"
              className="border-0"
              aria-current="true"
              style={{
                backgroundColor: '#2d353c',
                color: '#ffffff',
                fontSize: '15px',
              }}
            >
              <MDBIcon
                fas
                icon="shop"
                className="me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
              Shop
            </MDBListGroupItem>
            <MDBListGroupItem
              tag="a"
              href="/credits"
              className="border-0"
              aria-current="true"
              style={{
                backgroundColor: '#2d353c',
                color: '#ffffff',
                fontSize: '15px',
              }}
            >
              <MDBIcon
                fas
                icon="coins"
                className="me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
              My Credits
            </MDBListGroupItem>
            <p
              style={{
                marginLeft: '23px',
                marginBottom: '-2px',
                color: '#ffffff',
                fontSize: '15px',
                marginTop: '25px',
              }}
            >
              Others
            </p>
            <MDBListGroupItem
              tag="a"
              href="/profile"
              className="border-0"
              aria-current="true"
              style={{
                backgroundColor: '#2d353c',
                color: '#ffffff',
                fontSize: '15px',
              }}
            >
              <MDBIcon
                fas
                icon="user"
                className="me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
              My Profile
            </MDBListGroupItem>
            <MDBListGroupItem
              tag="a"
              className="border-0"
              aria-current="true"
              style={{
                backgroundColor: '#2d353c',
                color: '#ffffff',
                fontSize: '15px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (window.confirm('Are you sure you want to Logout?')) {
                  signOut();
                }
              }}
            >
              <MDBIcon
                icon="sign-out-alt"
                className="me-3"
                style={{ color: '#ffffff', textTransform: 'uppercase' }}
              />
              Logout
            </MDBListGroupItem>
            <p
              style={{
                marginLeft: '23px',
                marginBottom: '-2px',
                color: '#687987',
                fontSize: '15px',
                marginTop: '25px',
              }}
            >
              Support Status
            </p>
            <MDBListGroupItem
              className="border-0"
              aria-current="true"
              style={{
                backgroundColor: '#2d353c',
                color: '#ABAEB1',
                fontWeight: 'bold',
                fontSize: '0.8em',
              }}
            >
              <MDBRow className="row-cols-1 row-cols-md-1 g-1">
                <MDBCol>
                  <span
                    style={{
                      marginLeft: '-2px',
                      padding: '7px 15px',
                      borderRadius: '20px',
                      textShadow: '1px 1px 0px #000',
                      textTransform: 'uppercase',
                      marginTop: '5px',
                      backgroundColor:
                        supportActive === 1 ? 'red' : 'rgb(0 166 0)',
                      color: '#ffffff',
                    }}
                  >
                    We are
                    {' '}
                    {supportActive ? 'Online' : 'Offline'}
                  </span>
                </MDBCol>
              </MDBRow>
            </MDBListGroupItem>
          </MDBListGroup>
        </div>
        <div className="mx-3 mb-2 d-flex flex-col">
          <div>
            <Link to="/imprint" style={{ color: '#ABAEB1', fontSize: '15px' }}>
              Imprint
            </Link>
          </div>
          <div>
            <Link
              to="/conditions"
              style={{ color: '#ABAEB1', fontSize: '15px' }}
            >
              Conditions
            </Link>
          </div>
          <div>
            <Link
              to="/data-protection"
              style={{ color: '#ABAEB1', fontSize: '15px' }}
            >
              Data Protection
            </Link>
          </div>
        </div>
      </MDBCollapse>

      <MDBNavbar
        expand="lg"
        style={{ backgroundColor: '#2d353c', position: 'relative' }}
      >
        <MDBContainer fluid>
          <MDBNavbarNav className="d-flex flex-row align-items-center w-auto">
            <MDBNavbarToggler
              type="button"
              aria-label="Toggle navigation"
              onClick={toggleShow}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>
            <MDBNavbarBrand href="#">
              <img
                src="../Assets/logo.png"
                height="30"
                alt=""
                loading="lazy"
                className="h-8"
              />
            </MDBNavbarBrand>
          </MDBNavbarNav>
          <MDBNavbarNav className="d-flex flex-row justify-content-end w-auto">
            <MDBNavbarItem className="me-3 me-lg-0 d-flex align-items-center">
              <MDBDropdown>
                <MDBDropdownToggle
                  tag="a"
                  href="#!"
                  className="nav-link"
                  style={{ color: 'white', fontSize: '12px' }}
                >
                  {subscriptionData.length > 0
                    && subscriptionData.map((item, index) => (
                      <span key={index} style={{ marginRight: '10px' }}>
                        Subscription Expiry:
                        {' '}
                        {moment(item.dated).format('DD.MM.YYYY')}
                      </span>
                    ))}
                  {subscriptionData.length === 0 && (
                    <span style={{ marginRight: '10px' }}>No Subscription</span>
                  )}
                  {credits.map((item, index) => (
                    <span key={index} style={{ marginRight: '10px' }}>
                      Credits:
                      {' '}
                      {item.credits}
                    </span>
                  ))}
                  {/* <img src="https://mdbootstrap.com/img/Photos/Avatars/img (31).jpg" className="rounded-circle" height="22" alt="" loading="lazy" /> */}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem />
                  <MDBDropdownItem />
                  <MDBDropdownItem />
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
