import React, { useEffect } from 'react';
import {
  MDBRow, MDBCol,
} from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../hooks/AuthContext';

const col2Style = {
  height: '100%',
  overflow: 'auto',
  padding: '0',
  position: 'relative',
};

export default function Confirm() {
  const navigate = useNavigate();
  const { setUserSession, setDbUserId } = useAuth();

  const showEmailMessage = (message) => {
    toast.error(message, {
      position: 'top-center',
    });
  };

  const showErrorMessage = () => {
    toast.error('Something went wrong', {
      position: 'top-center',
    });
  };

  const handleRefreshAndNavigate = async () => {
    const userId = Cookies.get('userId');
    if(userId) {
      setUserSession(userId);
      setDbUserId(userId);
    }

    navigate('/dashboard');
  };

  const saveUserMetadata = async (session) => {
    const { user_metadata: userMetadata, id } = session.user;

    const dealerRegisterUrl = `${process.env.REACT_APP_BACKEND_URL}/Dealer/register`;

    const backendResponse = await axios.post(dealerRegisterUrl, {
      supabaseUserId: id,
      email: session.user.email,
      ...userMetadata,
    });
    const backendData = backendResponse.data;

    switch (backendData.message) {
      case 'already':
        showEmailMessage();
        break;
      case 'error':
        showErrorMessage();
        break;
      default:
        handleRefreshAndNavigate(session);
        break;
    }
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) {
        return;
      }
      saveUserMetadata(session);
      setUserSession(session);
    });
  }, []);

  return (
    <MDBRow style={{
      margin: 0, width: '100%', overflow: 'hidden', backgroundColor: '#1A2229',
    }}
    >
      <ToastContainer />

      <MDBCol
        md="12"
        style={{
          ...col2Style, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <div style={{
          marginLeft: '20px', marginTop: '10%', padding: '30px', width: '500px',
        }}
        >
          <center>
            <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} />
          </center>
          <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>
            Cofirm your email
          </h3>
          <div>
            <p style={{
              color: '#ffffff', background: '#00A86B', borderRadius: '18px', padding: '12px',
            }}
            >
              Please check your email for a link to log into your account.
              <span className="font-bold">You need to open the link from the email in the same browser.</span>
            </p>
          </div>

          <div style={{
            left: '0', right: '0', textAlign: 'center', marginTop: '25px',
          }}
          >
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>
              © ECULABs All Right Reserved
              {new Date().getFullYear()}
            </p>
            <div>
              <Link to="/imprint" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Imprint
              </Link>
              {' '}
              |
              {' '}
              <Link to="/conditions" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Conditions
              </Link>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
