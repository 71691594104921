import React, { useState, useEffect } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCheckbox,
  MDBCol,
  MDBBtn,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Form } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileUploader } from "react-drag-drop-files";
import Sidebar from "../parts/sidebar";
import { useAuth } from "../../hooks/AuthContext";

function Dashboard() {
  const { dbUserId } = useAuth();

  const [basicModal, setBasicModal] = useState(false);
  const [autofile, setAutoFile] = useState(null);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [mycontrollers, setMycontrollers] = useState([]);
  const [filename, setFilename] = useState("");
  const fileTypes = ["bin"];

  const [makes, setMakes] = useState([]);
  const [ecu, setECU] = useState([]);
  const [scripts, setScripts] = useState([]);

  const [make, setMake] = useState("");
  const [sendMake, setSendMake] = useState("");
  const [selectEcu, setSelectedEcu] = useState("");

  const [reqMake] = useState("");
  const [reqEcu] = useState("");
  const [Id, setId] = useState("");
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [comments, setComments] = useState("");
  const [submit, setSubmit] = useState(false);
  const [fuel] = useState("");
  const [filesubmit, setFileSubmit] = useState(false);

  const [possible, setPossible] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [selectedSolutions, setSelectedSolutions] = useState([]);
  const [selectedModalSolutions, setSelectedModalSolutions] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [downloadSubmit, setDownloadsubmit] = useState(false);
  const [binFile, setBinFile] = useState("");
  const [show, setShow] = useState(false);

  const solutionDescriptions = {
    ADBLUE: "Attention! In some cases you got to unplug the AdBlue and Nox control unit!",
    EGR: "Attention! In some cases you got to unplug the EGR control unit!",
    DPF: "Attention! Make sure you have reset the ash load (or rather registered a new DPF) before writing the modified file. In some cases you got to unplug the temperature and differential pressure sensor!",
    FLAPS: "Attention! In some cases you got to unplug the flap control unit!",
    NOX: "Attention! In some cases you got to unplug the Nox control unit!",
    DECAT: "Attention! In some cases you got to unplug the temperature sensor!",
    IMMO: "Attention! In some cases you got to unplug the immobilizer control unit!",
    TVA: "Attention! In some cases you got to unplug the throttle valve!",
    MAF: "Attention! In some cases you got to unplug the mass air flow sensor!",
    LIMP: "Attention! Some flashers are currently unable to correct the checksums for this solution!",
    VMAX: "Attention! In some cases you additionally have to deacivate the VMAX in TCU / CPC / Cluster!",
    STAGE2:
      "Attention! On diesel engines, you additionally have to remove and deactivate DPF, Catalysator and EGR valve! On petrol engines you additionally have to remove catalysator and install an upgrade turbo intercooler!",
  };

  function replaceSpecialCharacters(fileName) {
    // Regular expression to match any special character except underscore (_),(.) and hyphen (-)
    const regex = /[^a-zA-Z0-9. ]/g;
    const str = fileName.replace(regex, "-");
    return str.replace(/--/g, "-");
  }
  const handleChange = (handleChangeFile) => {
    const isValidFileType = fileTypes.some((type) => handleChangeFile.name.toLowerCase().endsWith(`.${type}`));
    if (isValidFileType) {
      setAutoFile(handleChangeFile);
    }
  };
  function bytesToKb(bytes) {
    const megabytes = bytes / 1024;
    return megabytes.toFixed(2); // Round to two decimal places
  }

  const handleTypeError = () => {
    toast.error("Invalid file type. Please upload a BIN file.", {
      position: "top-center",
    });
  };

  const showErrorMessage = () => {
    toast.error("Give complete details", {
      position: "top-center",
    });
  };

  const showErrorMessageSolution = () => {
    toast.error("Choose Solution Type", {
      position: "top-center",
    });
  };

  const showLimitError = () => {
    toast.error("Your Daily solution limit reached", {
      position: "top-center",
    });
  };

  const showLowCredits = () => {
    toast.error("You have not enough credits for this solution", {
      position: "top-center",
    });
  };

  const getDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/Admin/getSolutions`, {
        headers: {
          "Content-Type": "application/json",
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setSolutions(responseData.data);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  async function checker() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Admin/getCars`, {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });

      const controllersResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Admin/getControllers`, {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });

      const responseScripts = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Dealer/getScripts`, {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });

      const data = await response.json();
      const controllersData = await controllersResponse.json();
      const datascript = await responseScripts.json();
      const sortedMakes = data.data.slice().sort((a, b) => {
        const nameA = a.carname.toLowerCase();
        const nameB = b.carname.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setMakes(sortedMakes);
      const sortedControllers = controllersData.data.slice().sort((a, b) => {
        const nameA = a.controllername.toLowerCase();
        const nameB = b.controllername.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setECU(sortedControllers);
      setScripts(datascript.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    checker();
    getDetails();
  }, []);

  const handleSelectedEcu = (event) => {
    setSelectedEcu(event.target.value);
  };

  const handleAutomatisation = async (e) => {
    e.preventDefault();
    setFileSubmit(true);

    if (make === "" || selectEcu === "") {
      showErrorMessage();
      setFileSubmit(false);
    } else {
      const formData = new FormData();
      formData.append("car", sendMake);
      formData.append("controller", selectEcu);
      formData.append("binFile", autofile, replaceSpecialCharacters(autofile.name));
      formData.append("fuel", fuel);

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Dealer/automatisation`, formData);

        const { data } = response;
        if (data.solutions.every((solutionname) => solutionname.solution === "notfound")) {
          setFileSubmit(false);
          setBasicModal(true);
        } else {
          setPossible(data.solutions);
          setBinFile(data.binFile);
          setFilename(data.filename);
          setFileSubmit(false);
          setShow(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setFileSubmit(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    if ((make === "" && reqMake === "") || (selectEcu === "" && reqEcu === "")) {
      showErrorMessage();
      setSubmit(false);
    } else {
      const form = e.target;
      const formData = new FormData();
      formData.append("make", sendMake);
      formData.append("ecu", selectEcu);
      formData.append("file", autofile, replaceSpecialCharacters(autofile.name));
      formData.append("comments", comments);
      formData.append("solution", selectedModalSolutions);
      formData.append("userId", dbUserId);

      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Dealer/requests`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const { data } = response;
        if (data.message === "added") {
          setId(data.id);
          form.reset();
          setSubmit(false);
          setBasicModal(false);
          setOpenRequestModal(true);
        }
      } catch (error) {
        console.error("Error:", error.message);
        setSubmit(false);
      }
    }
  };

  function downloadFileFromBackend(filePath, downloadFileFilename) {
    try {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = filePath;
      link.download = downloadFileFilename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  async function handleDownload(e) {
    e.preventDefault();
    setDownloadsubmit(true);
    const formData = new FormData();

    if (selectedFile.length === 0) {
      showErrorMessageSolution();
      setDownloadsubmit(false);
    } else {
      try {
        const response = await axios.post(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/Dealer/downloadfile?file=${selectedFile}&filename=${filename}&binfile=${binFile}&car=${sendMake}&controller=${selectEcu}&userid=${dbUserId}&solution=${JSON.stringify(
            selectedSolutions
          )}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        const { data } = response;

        if (data.message === "limit") {
          setDownloadsubmit(false);
          showLimitError();
        } else if (data.message === "lowcredits") {
          setDownloadsubmit(false);
          showLowCredits();
        } else {
          await downloadFileFromBackend(`${process.env.REACT_APP_BACKEND_URL}/solutions/${data.file}`, data.file);
          setConfirmSubmit(false);
          setDownloadsubmit(false);
        }
      } catch (error) {
        console.error("Error:", error.message);
        setDownloadsubmit(false);
      }
    }
  }

  const handleCheckboxChange = (solutionName, handleCheckboxFile) => {
    const isSolutionSelected = selectedSolutions.includes(solutionName);

    if (isSolutionSelected) {
      setSelectedSolutions((prevSelected) => prevSelected.filter((selected) => selected !== solutionName));
      setSelectedFile((prevSelected) => prevSelected.filter((selected) => selected !== handleCheckboxFile));
    } else {
      setSelectedSolutions((prevSelected) => [...prevSelected, solutionName]);
      setSelectedFile((prevSelected) => [...prevSelected, handleCheckboxFile]);
    }
  };

  const handleCheckboxChangeInModal = (solutionName) => {
    const isSolutionSelected = selectedModalSolutions.includes(solutionName);

    if (isSolutionSelected) {
      setSelectedModalSolutions((prevSelected) => prevSelected.filter((selected) => selected !== solutionName));
    } else {
      setSelectedModalSolutions((prevSelected) => [...prevSelected, solutionName]);
    }
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <ToastContainer />
      <Sidebar />
      <center>
        <MDBRow className="justify-content-center">
          <MDBCol md={8}>
            <form onSubmit={handleAutomatisation}>
              <div className="main-content">
                <h3
                  style={{
                    color: "white",
                    marginLeft: "2%",
                    marginTop: "2%",
                  }}
                >
                  Choose Your ECU / TCU
                </h3>
                <MDBCard className="text-center" style={{ margin: "30px", backgroundColor: "#2d353c" }}>
                  <MDBCardBody>
                    <MDBRow style={{ marginTop: "15px" }}>
                      <MDBCol md="6">
                        <Form>
                          <Form.Group controlId="mySelect" style={{ marginTop: "-15px" }}>
                            <Form.Select
                              style={{
                                backgroundColor: "#6c757d",
                                border: "none",
                                color: "#ffffff",
                              }}
                              value={make}
                              id="usermake"
                              onChange={(event) => {
                                const selectedValue = event.target.value;
                                const selectedText = event.target.options[event.target.selectedIndex].text;
                                setMake(selectedValue);
                                setSendMake(selectedText);
                                setMycontrollers(ecu.filter((item) => item.makeId === +selectedValue));
                              }}
                            >
                              <option value="">Select make</option>
                              {makes.map((item) => (
                                <option value={item.Id} key={item.Id}>
                                  {item.carname}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Form>
                      </MDBCol>
                      <MDBCol md="6">
                        <Form>
                          <Form.Group controlId="mySelect" style={{ marginTop: "-15px" }}>
                            <Form.Select
                              style={{
                                backgroundColor: "#6c757d",
                                border: "none",
                                color: "#ffffff",
                              }}
                              value={selectEcu}
                              onChange={handleSelectedEcu}
                            >
                              <option value="">Select controller</option>
                              {mycontrollers.map((item, index) => (
                                <option value={item.controllername} key={index}>
                                  {item.controllername}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Form>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md={12}>
                        <div style={{ marginTop: "20px" }}>
                          <FileUploader
                            onTypeError={handleTypeError}
                            handleChange={handleChange}
                            name="file"
                            types={fileTypes}
                            multiple={false}
                          />
                          {autofile && (
                            <div className="d-flex align-items-center mt-4 p-1 text-white">
                              <div>
                                <MDBIcon far icon="file" />
                              </div>
                              <div className="ms-3 text-start">
                                <p className="fw-bold mb-0">{autofile.name}</p>
                                <p className="fw-bold mb-1 mr-auto">{bytesToKb(autofile.size)} KB</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol md={12} style={{ textAlign: "center" }}>
                        <MDBBtn
                          style={{
                            backgroundColor: "white",
                            maxWidth: "350px",
                            color: "black",
                          }}
                        >
                          {filesubmit ? <MDBSpinner /> : <span>Check for tuning and solutions</span>}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </div>
              <div style={{ marginLeft: "16%" }} />
            </form>
            {show ? (
              <div>
                <div className="main-content" style={{ marginTop: "80px" }}>
                  <h3 style={{ color: "white", marginLeft: "2%" }}>Choose Your Solution </h3>
                  <MDBCard className="text-center label-left" style={{ margin: "30px", backgroundColor: "#2d353c" }}>
                    <MDBCardBody>
                      <MDBRow style={{ margin: "20px" }}>
                        <MDBCol md={4}>
                          <p
                            style={{
                              color: "white",
                              textAlign: "left",
                              fontWeight: "bold",
                            }}
                          >
                            Deactivations
                          </p>
                          <div style={{ marginLeft: "2%" }}>
                            {solutions
                              .filter(
                                (item) =>
                                  item.category === "Deactivation" &&
                                  possible.some((p) => p.solution === item.solutionname)
                              )
                              .map((solution, index) => {
                                const label = "Automatic";
                                const description = solutionDescriptions[solution.solutionname];
                                return (
                                  <div className="commentHover" key={index}>
                                    <MDBCheckbox
                                      name="deactivationGroup"
                                      value={solution.solutionname}
                                      id={solution.solutionname}
                                      checked={selectedSolutions.includes(solution.solutionname)}
                                      onChange={() => {
                                        const possibleObject = possible.find(
                                          (item) => item.solution === solution.solutionname
                                        );
                                        handleCheckboxChange(solution.solutionname, possibleObject.fileName);
                                      }}
                                      label={
                                        <span style={{ color: "white" }}>
                                          {solution.solutionname}
                                          <span
                                            style={{
                                              backgroundColor: "green",
                                              borderRadius: "5px",
                                              padding: "5px",
                                              fontSize: "10px",
                                              marginLeft: "8px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {label}
                                          </span>
                                        </span>
                                      }
                                    />
                                    {description && <div className="commentBox">{description}</div>}
                                  </div>
                                );
                              })}
                          </div>
                        </MDBCol>
                        <MDBCol md={4}>
                          <p
                            style={{
                              color: "white",
                              textAlign: "left",
                              fontWeight: "bold",
                            }}
                          >
                            Tuning
                          </p>
                          <div style={{ marginLeft: "2%" }}>
                            {solutions
                              .filter(
                                (item) =>
                                  item.category === "Tuning" && possible.some((p) => p.solution === item.solutionname)
                              )
                              .map((solution, index) => {
                                const label = "Automatic"; // Since we are only including automatic solutions in the filter

                                return (
                                  <div key={index}>
                                    <MDBCheckbox
                                      name="deactivationGroup"
                                      value={solution.solutionname}
                                      id={solution.solutionname}
                                      checked={selectedSolutions.includes(solution.solutionname)}
                                      onChange={() => {
                                        const possibleObject = possible.find(
                                          (item) => item.solution === solution.solutionname
                                        );
                                        handleCheckboxChange(solution.solutionname, possibleObject.fileName);
                                      }}
                                      label={
                                        <span style={{ color: "white" }}>
                                          {solution.solutionname}{" "}
                                          <span
                                            style={{
                                              backgroundColor: "green",
                                              borderRadius: "5px",
                                              padding: "5px",
                                              fontSize: "10px",
                                              marginLeft: "8px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {label}
                                          </span>
                                        </span>
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </MDBCol>
                        <MDBCol md={4}>
                          <p
                            style={{
                              color: "white",
                              textAlign: "left",
                              fontWeight: "bold",
                            }}
                          >
                            Miscle
                          </p>
                          <div style={{ marginLeft: "2%" }}>
                            {solutions
                              .filter(
                                (item) =>
                                  item.category === "Miscle" && possible.some((p) => p.solution === item.solutionname)
                              )
                              .map((solution, index) => {
                                const label = "Automatic";

                                return (
                                  <div key={index}>
                                    <MDBCheckbox
                                      name="deactivationGroup"
                                      value={solution.solutionname}
                                      id={solution.solutionname}
                                      checked={selectedSolutions.includes(solution.solutionname)}
                                      onChange={() => {
                                        const possibleObject = possible.find(
                                          (item) => item.solution === solution.solutionname
                                        );
                                        handleCheckboxChange(solution.solutionname, possibleObject.fileName);
                                      }}
                                      label={
                                        <span style={{ color: "white" }}>
                                          {solution.solutionname}{" "}
                                          <span
                                            style={{
                                              backgroundColor: "green",
                                              borderRadius: "5px",
                                              padding: "5px",
                                              fontSize: "10px",
                                              marginLeft: "8px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {label}
                                          </span>
                                        </span>
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow
                        style={{
                          margin: "20px",
                          marginTop: "50px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <MDBCol md={3}>
                          <MDBBtn
                            style={{
                              backgroundColor: "#c9273f",
                              width: "100%",
                              marginTop: "5px",
                            }}
                            onClick={() => {
                              setConfirmSubmit(true);
                            }}
                          >
                            Download File
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol md={3}>
                          <MDBBtn
                            style={{
                              backgroundColor: "#c9273f",
                              width: "100%",
                              marginTop: "5px",
                            }}
                            onClick={() => {
                              setBasicModal(true);
                            }}
                          >
                            Request Solution
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </div>
              </div>
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>
      </center>

      <MDBModal show={basicModal} onHide={() => setBasicModal(false)} tabIndex="-1">
        <MDBModalDialog centered size="lg">
          <MDBModalContent style={{ backgroundColor: "#303030", color: "white" }}>
            <MDBModalBody>
              <center>
                <h3 style={{ color: "white" }}>OPEN NEW REQUEST</h3>
              </center>
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <MDBRow style={{ marginTop: "25px" }}>
                  <MDBCol md={4}>
                    <p style={{ color: "white", marginLeft: "2%" }}>Deactivations</p>
                    <div style={{ marginLeft: "2%" }}>
                      {solutions
                        .filter((item) => item.category === "Deactivation")
                        .map((solution, index) => (
                          <div key={index}>
                            <MDBCheckbox
                              name="deactivationGroup"
                              value={solution.solutionname}
                              id={solution.solutionname}
                              checked={selectedModalSolutions.includes(solution.solutionname)}
                              onChange={() => handleCheckboxChangeInModal(solution.solutionname)}
                              label={<span style={{ color: "white" }}>{solution.solutionname}</span>}
                            />
                          </div>
                        ))}
                    </div>
                  </MDBCol>
                  <MDBCol md={4}>
                    <p style={{ color: "white", marginLeft: "2%" }}>Tuning</p>
                    <div style={{ marginLeft: "2%" }}>
                      {solutions
                        .filter((item) => item.category === "Tuning")
                        .map((solution, index) => (
                          <div key={index}>
                            <MDBCheckbox
                              name="flexCheck"
                              value={solution.solutionname}
                              id={solution.solutionname}
                              checked={selectedModalSolutions.includes(solution.solutionname)}
                              onChange={() => handleCheckboxChangeInModal(solution.solutionname)}
                              label={<span style={{ color: "white" }}>{solution.solutionname}</span>}
                            />
                          </div>
                        ))}
                    </div>
                  </MDBCol>
                  <MDBCol md={4}>
                    <p style={{ color: "white", marginLeft: "2%" }}>Miscellaneous</p>
                    <div style={{ marginLeft: "2%" }}>
                      {solutions
                        .filter((item) => item.category === "Miscle")
                        .map((solution, index) => (
                          <div key={index}>
                            <MDBCheckbox
                              name="flexCheck"
                              value={solution.solutionname}
                              checked={selectedModalSolutions.includes(solution.solutionname)}
                              onChange={() => handleCheckboxChangeInModal(solution.solutionname)}
                              id={solution.solutionname}
                              label={<span style={{ color: "white" }}>{solution.solutionname}</span>}
                            />
                          </div>
                        ))}
                    </div>
                  </MDBCol>
                </MDBRow>

                <Form.Control
                  as="textarea"
                  placeholder="Your Comments"
                  rows={4}
                  size="lg"
                  id="card"
                  name="comments"
                  onChange={(event) => setComments(event.target.value)}
                  style={{
                    color: "white",
                    backgroundColor: "#303030",
                    borderRadius: "3px",
                    flex: 1,
                    marginTop: "7px",
                  }}
                />
                <center>
                  <MDBBtn style={{ backgroundColor: "#c9273f", marginTop: "5px" }}>
                    {submit ? <MDBSpinner /> : <span>Open New Request</span>}
                  </MDBBtn>
                </center>
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={openRequestModal} onHide={() => setOpenRequestModal(false)} tabIndex="-1">
        <MDBModalDialog centered size="lg">
          <MDBModalContent style={{ backgroundColor: "#303030", color: "white" }}>
            <MDBModalBody>
              <center>
                <h3 style={{ color: "white", marginTop: "15px" }}>OPEN NEW REQUEST</h3>
              </center>

              <center>
                <div>
                  <p style={{ marginTop: "30px" }}>Your request was opened successfully.</p>
                  <h3 style={{ marginTop: "15px", marginBottom: "15px" }}>The request number is #{Id}</h3>
                  <p style={{ marginBottom: "40px" }}>
                    Our team will process your file and get in
                    <br /> touch with you as soon as possible.
                  </p>
                </div>
              </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={confirmSubmit} onHide={() => setConfirmSubmit(false)} tabIndex="-1">
        <MDBModalDialog centered size="lg">
          <MDBModalContent style={{ backgroundColor: "#303030", color: "white" }}>
            <MDBModalBody>
              <center>
                <h3 style={{ color: "white", marginTop: "15px" }}>CONFIRMATION</h3>
              </center>

              <center>
                <div>
                  <p style={{ marginTop: "30px" }}>
                    you are aware not all solutions are legal. This software is intended for testing purposes only and
                    should not be used on public roads. Please note that the checksum has not been corrected.
                  </p>
                </div>
                <center>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MDBBtn
                      style={{
                        backgroundColor: "#c9273f",
                        color: "white",
                        marginRight: "10px",
                      }}
                      onClick={handleDownload}
                    >
                      {downloadSubmit ? <MDBSpinner /> : <span>Confirm</span>}
                    </MDBBtn>
                    <MDBBtn
                      style={{ backgroundColor: "white", color: "black" }}
                      onClick={() => {
                        setConfirmSubmit(false);
                      }}
                    >
                      Cancel
                    </MDBBtn>
                  </div>
                </center>
              </center>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={filesubmit} tabIndex="-1">
        <div className="file-submit-modal">
          <i className="fa-solid fa-gear" />
          <br />
          <br />
          <div className="file-submit-col">
            <h3 className="file-submit__headline">Please wait. Our AI is checking your file.</h3>
          </div>
        </div>
      </MDBModal>
    </div>
  );
}

export default Dashboard;
