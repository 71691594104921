import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuthContext = createContext();

export function AuthProvider({ children }) {  
  const [submit, setSubmit] = useState(false);
  const [userSession, setUserSession] = useState(null);
  const [dbUserId, setDbUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(false);

    const userId = Cookies.get('userId');

    if(userId) {
      setDbUserId(userId);
      setUserSession(userId);
    } else {
      signOut();
    } 
  }, []);

  // Signup, Signin and Signout methods
  const signUp = ({ email, options }) => (null);

  const signIn = async (email, password) => {
    const data = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Dealer/login`, data, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if (responseData.message === 'success') {
        Cookies.set('email', responseData.email, { expires: 1 });
        Cookies.set('userId', responseData.userid, { expires: 1 });
        setUserSession(responseData.userid);
        setDbUserId(responseData.userid);
        navigate('/dashboard');
      } else if (responseData.message === 'invalid') {
        showErrorMessage();
      }
      setSubmit(false);
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }

    //navigate('/confirm');

    // if (error) {
    //   navigate('/register?show-register-message=true');
    // }
  };

  const signOut = (navigateAfterLogout = true) => {
    Cookies.remove('email');
    Cookies.remove('userId');
    setDbUserId(null);
    setUserSession(null);
    if(navigateAfterLogout === true) {
      navigate('/');
    }
  };

  const resetPasswordForEmail = async (email) => (null);
  // const resetPasswordForEmail = async (email) => supabase.auth.resetPasswordForEmail(email, {
  //   redirectTo: `${process.env.REACT_APP_FRONTEND_BASE_URL}/change-password`,
  // });

  const updatePassword = async (password) => (null);

  // Return the user object and auth methods
  return (
    <AuthContext.Provider
      value={{
        userSession,
        signUp,
        signIn,
        signOut,
        loading,
        dbUserId,
        resetPasswordForEmail,
        updatePassword,
        setUserSession,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
}

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
