import React from 'react';
import {
  MDBRow, MDBCol,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

export default function Imprint() {
  return (
    <MDBRow style={{
      margin: 0, width: '100%', backgroundColor: '#1A2229',
    }}
    >
      <MDBCol
        md="12"
        style={{
          ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh',
        }}
      >
        <div style={{ marginLeft: '20px', padding: '20px' }} className="mb-36">
          <center>
            <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} />
          </center>
          <h2 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>Imprint</h2>

          <div className="prose text-white w-full max-w-3xl">
            <h2 className="text-white">
              Eculab.ai
            </h2>

            <p>
            </p>

            <h2 className="text-white">
              Kontakt
            </h2>

            <p>
            </p>

            

          </div>

          <div className="fixed bottom-0 left-0 w-full text-center bg-[#1a2229] py-4">
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>
              © Eculab All Right Reserved
              {' '}
              {new Date().getFullYear()}
            </p>
            <div>
              <Link to="/imprint" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Imprint
              </Link>
              {' '}
              |
              {' '}
              <Link to="/conditions" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Conditions
              </Link>
              {' '}
              |
              {' '}
              <Link to="/data-protection" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Data Protection
              </Link>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
