import React, { useState } from 'react';
import {
  MDBRow, MDBCol, MDBInput, MDBBtn, MDBSpinner,
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

export default function Forgot() {
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [resetInProcess, setResetInProcess] = useState(false);

  const { resetPasswordForEmail, userSession } = useAuth();

  const navigate = useNavigate();

  if (userSession) {
    navigate('/dashboard');
  }

  const showErrorMessage = () => {
    toast.error('Email not found', {
      position: 'top-center',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);

    try {
      resetPasswordForEmail(email);
      setSubmit(false);
      setResetInProcess(true);
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
      setResetInProcess(false);
      showErrorMessage();
    }
  };

  return (
    <MDBRow style={{
      margin: 0, width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: '#1A2229',
    }}
    >
      <ToastContainer />
      <MDBCol
        md="12"
        style={{
          ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <div style={{ padding: '20px', width: '400px' }}>
          <center>
            <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} />
          </center>
          <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>Forgot Password</h3>
          <p style={{ color: '#405e77' }}>Reset Your Password</p>
          { resetInProcess ? (

            <div>
              <p style={{
                color: '#ffffff', background: '#00A86B', borderRadius: '18px', padding: '12px',
              }}
              >
                Please check your email for a link to reset your password.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div>
                <MDBInput
                  label="Email Address"
                  id="email"
                  name="email"
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ backgroundColor: '#6c757d' }}
                />
              </div>
              <MDBBtn style={{
                marginTop: '30px', width: '100%', backgroundColor: '#c32941', color: 'white',
              }}
              >
                {submit ? (
                  <MDBSpinner />
                ) : (
                  <span>Proceed</span>
                )}
              </MDBBtn>
            </form>
          )}

          <div style={{
            position: 'absolute', bottom: '20px', left: '0', right: '0', textAlign: 'center',
          }}
          >
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>
              © Eculab All Right Reserved
              {new Date().getFullYear()}
            </p>
            <div>
              <Link to="/imprint" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Imprint
              </Link>
              {' '}
              |
              {' '}
              <Link to="/conditions" style={{ color: '#ABAEB1', fontSize: '15px' }}>
                Conditions
              </Link>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
