import React, { useEffect, useState } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';
import moment from 'moment';
import Sidebar from '../parts/sidebar';
import { useAuth } from '../../hooks/AuthContext';

function Credits() {
  const [data, setData] = useState([]);
  const [credits, setCredits] = useState(null);

  const { dbUserId } = useAuth();

  async function subscribe() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/subCredits?userId=${dbUserId}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const subCreditsData = await response.json();
      setData(subCreditsData.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function credit() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/getCredits?userId=${dbUserId}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const creditData = await response.json();

      setCredits(creditData.data[0].credits);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    subscribe();
    credit();
  }, []);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />

      <div className="main-content">
        <div style={{ marginTop: '2%', marginLeft: '3%' }}>
          <h3 style={{ color: 'white' }}>My Credits</h3>
          <center>
            <div>
              <MDBRow
                className="row-cols-1 row-cols-md-3 g-4 subscription-boxes"
                style={{ margin: '30px' }}
              >
                <MDBCol>
                  <MDBCard
                    className="h-100"
                    style={{ backgroundColor: '#2d353c', borderRadius: '0' }}
                  >
                    <MDBCardBody style={{ color: '#ffffff' }}>
                      <MDBCardTitle>My Subscription Status</MDBCardTitle>
                      <MDBCardText>
                        {data.length === 0 ? (
                          <>
                            <p
                              style={{
                                fontSize: '1.2em',
                                fontWeight: 'bold',
                                marginTop: '20px',
                                color: '#FF0000',
                              }}
                            >
                              NOT ACTIVE
                            </p>
                            <p>
                              Visit&nbsp;
                              <a
                                href="/subscriptions"
                                style={{
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                  color: '#fff',
                                }}
                              >
                                this page
                              </a>
                              to buy a subscription and save money
                            </p>
                          </>
                        ) : (
                          data.map((item, index) => (
                            <div key={index}>
                              <p
                                style={{
                                  fontSize: '1.2em',
                                  fontWeight: 'bold',
                                  marginTop: '20px',
                                  color: 'rgb(38 233 38)',
                                }}
                              >
                                ACTIVE
                              </p>
                              <p>
                                Expiration Date:
                                <br />
                                {moment(item.dated).format('DD.MM.YYYY')}
                              </p>
                            </div>
                          ))
                        )}
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
                <MDBCol>
                  <MDBCard
                    className="h-100"
                    style={{ backgroundColor: '#2d353c', borderRadius: '0' }}
                  >
                    <MDBCardBody style={{ color: '#ffffff' }}>
                      <MDBCardTitle>My Credits</MDBCardTitle>
                      <MDBCardText>
                        <p
                          style={{
                            fontSize: '1.2em',
                            fontWeight: 'bold',
                            marginTop: '20px',
                          }}
                        >
                          {' '}
                          {credits}
                        </p>
                        <p>
                          Visit&nbsp;
                          <a
                            href="/subscriptions"
                            style={{
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              color: '#fff',
                            }}
                          >
                            this page
                          </a>
                          to buy more credits for tuning and solutions
                        </p>
                      </MDBCardText>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
}

export default Credits;
