import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

function Success() {
  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
    }}
    >
      <MDBCard style={{ backgroundColor: '#2d353c' }}>
        <center>
          <MDBCardBody>
            <img src="./Assets/success.png" alt="" style={{ width: '100px', marginBottom: '15px' }} />
            <MDBCardTitle style={{ color: 'white' }}>Payment Successful</MDBCardTitle>
            <MDBCardText style={{ color: '#a0aeb1' }}>
              Your payment has been recieved successfully
            </MDBCardText>
            <MDBBtn style={{ backgroundColor: '#ffffff', color: 'black' }}><Link to="/dashboard">Go to Home Page</Link></MDBBtn>
          </MDBCardBody>
        </center>
      </MDBCard>
    </div>
  );
}

export default Success;
