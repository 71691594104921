import React, { useEffect, useState } from 'react';
import {
  MDBInput, MDBRow, MDBCol, MDBBtn, MDBSpinner,
} from 'mdb-react-ui-kit';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Sidebar from '../parts/sidebar';
import { useAuth } from '../../hooks/AuthContext';

function Profile() {
  const [countries, setCountries] = useState([]);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [post, setPost] = useState('');
  const [credits, setCredits] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [isVatFree, setIsVatFree] = useState('');
  const [userType, setUserType] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [submit, setSubmit] = useState(false);

  const { dbUserId } = useAuth();

  async function getRequests() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Dealer/profile?id=${dbUserId}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );

      const data = await response.json();
      console.log(data);
      setFirstname(data.data[0].firstname);
      setLastname(data.data[0].lastname);
      setEmail(data.data[0].email);
      setPhone(data.data[0].phone);
      setSelectedCountry(data.data[0].location);
      setCity(data.data[0].city);
      setAddress(data.data[0].address);
      setPost(data.data[0].postcode);
      setCredits(data.data[0].credits);
      setUserType(data.data[0].type);
      setVatNumber(data.data[0].vatNumber);
      setIsVatFree(data.data[0].isVatFree);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  useEffect(() => {
    axios
      .get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const countryList = response.data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
        }));
        setCountries(countryList);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
    getRequests();
  }, []);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const showSuccessMessage = () => {
    toast.success('Your profile has been updated', {
      position: 'top-center',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('location', selectedCountry);
    formData.append('postcode', post);
    formData.append('city', city);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('credits', credits);
    formData.append('type', userType);
    formData.append('vatNumber', vatNumber);
    formData.append('isVatFree', isVatFree);
    formData.append('id', dbUserId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/Admin/updateUser`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        },
      );
      const { data } = response;
      if (data.message === 'updated') {
        setSubmit(false);
        showSuccessMessage();
      }
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />

      <div className="main-content">
        <div style={{ marginTop: '2%', marginLeft: '3%' }}>
          <h3 style={{ color: 'white' }}>My Profile</h3>
          <div style={{ margin: '20px' }}>
            <center>
              <MDBRow className="justify-content-center">
                <MDBCol md={8}>
                  <form onSubmit={handleSubmit}>
                    <MDBRow className="justify-content-center">
                      <MDBCol md={6}>
                        {/* Erste Spalte */}
                        <MDBInput
                          label="First Name"
                          id="fname"
                          value={firstname}
                          type="text"
                          style={{ color: 'white' }}
                          onChange={(e) => {
                            setFirstname(e.target.value);
                          }}
                        />
                        <MDBInput
                          label="Last Name"
                          id="lname"
                          value={lastname}
                          onChange={(e) => {
                            setLastname(e.target.value);
                          }}
                          type="text"
                          style={{ color: 'white', marginTop: '8px' }}
                        />
                        <MDBInput
                          label="Email"
                          id="email"
                          type="text"
                          value={email}
                          style={{ color: 'white', marginTop: '8px' }}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <MDBInput
                          label="Phone"
                          id="phone"
                          type="text"
                          value={phone}
                          style={{ color: 'white', marginTop: '8px' }}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                        <MDBInput
                          label="Credits"
                          id="credits"
                          type="text"
                          value={credits}
                          style={{ color: 'white', marginTop: '8px' }}
                        />
                      </MDBCol>
                      <MDBCol md={6}>
                        {/* Zweite Spalte */}
                        <MDBInput
                          label="Address"
                          id="address"
                          type="text"
                          value={address}
                          style={{ color: 'white' }}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                        <MDBInput
                          label="Post Code"
                          id="postcode"
                          type="text"
                          value={post}
                          style={{ color: 'white', marginTop: '8px' }}
                          onChange={(e) => {
                            setPost(e.target.value);
                          }}
                        />
                        <MDBInput
                          label="Vat Number"
                          id="vatNumber"
                          type="text"
                          value={vatNumber}
                          style={{ color: 'white', marginTop: '8px' }}
                          onChange={(e) => setVatNumber(e.target.value)}
                        />
                        <MDBInput
                          label="City"
                          id="city"
                          type="text"
                          value={city}
                          style={{ color: 'white', marginTop: '8px' }}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                        <select
                          label="Country"
                          className="form-control"
                          style={{
                            marginTop: '5px',
                            color: 'white',
                            backgroundColor: '#1a2229',
                          }}
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          name="country"
                          required
                        >
                          <option value="">Select Country</option>
                          {countries.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                        <MDBBtn
                          style={{
                            marginTop: '20px',
                            width: '100%',
                            backgroundColor: 'white',
                            color: 'black',
                          }}
                        >
                          {submit ? (
                            <MDBSpinner />
                          ) : (
                            <span>Update Profile</span>
                          )}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="justify-content-center">
                      <MDBCol md={12} />
                    </MDBRow>
                  </form>
                </MDBCol>
              </MDBRow>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
